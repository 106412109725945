import React, { useEffect } from 'react';
import { ToastContainer } from './styles';

interface ToastProps {
    message: string;
    type: 'success' | 'error';
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, type, onClose }) => {
useEffect(() => {
    const timer = setTimeout(onClose, 3000); // Close the toast after 3 seconds
    return () => clearTimeout(timer);
}, [onClose]);

return (
    <ToastContainer type={type}>
    {message}
    <button onClick={onClose} style={{ marginLeft: '20px', background: 'none', border: 'none', color: 'white', cursor: 'pointer' }}>
        X
    </button>
    </ToastContainer>
);
};

export default Toast;