import React, { useState, RefObject, forwardRef } from 'react';
import logo from '../../assets/logo.svg';
import { Container, Logo, Nav, Hamburger, DropdownMenu, DropdownItem } from './styles';
import NavLink from '../NavLink';

interface HeaderProps {
  onWorkClick: () => void;
  onAboutClick: () => void;
  onContactClick: () => void;
}

const Header = forwardRef<HTMLElement, HeaderProps>(({ onWorkClick, onAboutClick, onContactClick }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container ref={ref as RefObject<HTMLElement>}>
      <Logo src={logo} alt="Logo" />
      <Hamburger onClick={toggleDropdown}>
        <span />
        <span />
        <span />
      </Hamburger>
      <DropdownMenu isOpen={isOpen}>
        <DropdownItem>
          <NavLink onClick={onWorkClick} customStyle="background-color: transparent; color: black; &:hover { color: #ec2b91; background-color: transparent; text-align: center;}">Work</NavLink>
        </DropdownItem>
        <DropdownItem>
          <NavLink onClick={onAboutClick} customStyle="background-color: transparent; color: black; &:hover { color: #ec2b91; background-color: transparent; text-align: center;}">About Us</NavLink>
        </DropdownItem>
        <DropdownItem>
          <NavLink onClick={onContactClick} customStyle="background-color: transparent; color: black; &:hover { color: #ec2b91; background-color: transparent; text-align: center;}">Contact</NavLink>
        </DropdownItem>
      </DropdownMenu>
      <Nav>
        <ul>
          <li><NavLink onClick={onWorkClick}>Work</NavLink></li>
          <li><NavLink onClick={onAboutClick}>About Us</NavLink></li>
          <li><NavLink onClick={onContactClick}>Contact</NavLink></li>
        </ul>
      </Nav>
    </Container>
  );
});

export default Header;
