import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ClashDisplay';
    src: url('/fonts/ClashDisplay-Regular.woff2') format('woff2'),
         url('/fonts/ClashDisplay-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'ClashDisplay';
    src: url('/fonts/ClashDisplay-Bold.woff2') format('woff2'),
         url('/fonts/ClashDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'ClashDisplay';
    src: url('/fonts/ClashDisplay-Extralight.woff2') format('woff2'),
         url('/fonts/ClashDisplay-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'ClashDisplay';
    src: url('/fonts/ClashDisplay-Light.woff2') format('woff2'),
         url('/fonts/ClashDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'ClashDisplay';
    src: url('/fonts/ClashDisplay-Medium.woff2') format('woff2'),
         url('/fonts/ClashDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'ClashDisplay';
    src: url('/fonts/ClashDisplay-Semibold.woff2') format('woff2'),
         url('/fonts/ClashDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'ClashDisplay', sans-serif;
  }

  body {
    background-color: #f9f9f9;
    color: #333;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
  }

  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  }
`;

export default GlobalStyles;
