import React from 'react';
import { AboutContainer, Paragraph, TextContainer, Title, ContentWrapper } from './styles';
import Fade from 'react-reveal/Fade';

const AboutUs: React.FC = () => (
  <Fade bottom>
    <AboutContainer>
      <ContentWrapper>
        <TextContainer>
          <Title>About Us</Title>
          <Paragraph>
            We specialize in remote solutions for lead generation, business support, and data analytics, 
            designed to help your company thrive from any location. Our LEDGENS team delivers highly targeted leads, 
            efficient support services, and actionable insights tailored to your needs. With our comprehensive approach, 
            we drive growth and enhance efficiency, ensuring your business achieves its full potential no matter where you are.
          </Paragraph>
        </TextContainer>
      </ContentWrapper>
    </AboutContainer>
  </Fade>
);

export default AboutUs;
