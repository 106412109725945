import React from 'react';
import { Container, Subtitle, Title, VideoBackground, Overlay } from './styles';

const HeroSection: React.FC = () => (
  <Container>
    <VideoBackground autoPlay loop muted style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}>
      <source src="/hero-video.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </VideoBackground>
    <Overlay />
    <Title>SK LEDGENS</Title>
    <Subtitle>Your Remote Growth Partners!</Subtitle>
  </Container>
);

export default HeroSection;