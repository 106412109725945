import emailjs from 'emailjs-com';

const EMAILJS_USER_ID = 'QInyHly6rfwW0pbg3'; // Replace with your actual EmailJS User ID
const EMAILJS_SERVICE_ID = 'service_84n40dl'; // Replace with your actual EmailJS Service ID
const EMAILJS_TEMPLATE_ID = 'template_85yzj98'; // Replace with your actual EmailJS Template ID
const EMAILJS_SUBSCRIPTION_TEMPLATE_ID = 'template_s26ar85';

// Initialize EmailJS with your User ID
emailjs.init(EMAILJS_USER_ID);

export const sendEmail = async (formData: {
  name: string;
  email: string;
  subject: string;
  message: string;
}) => {
  try {
    const response = await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, formData);
    return response;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export const sendSubscribeEmail = async (email: string) => {
  try {
    const response = await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_SUBSCRIPTION_TEMPLATE_ID, { email });
    return response;
  } catch (error) {
    console.error('Error sending subscription email:', error);
    throw error;
  }
};
