import styled from 'styled-components';

export const ServicesContainer = styled.section`
  padding: 50px 0;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

export const ServicesGrid = styled.div`
  display: flex;
  gap: 10px;
  justify-items: center;
  align-items: center;

  @media (max-width: 1300px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ServiceCard = styled.div`
  padding: 10px;
  width: 300px;
  perspective: 1000px;
  text-align: center;

  &:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
`;

export const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 450px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
`;

export const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  background-color: #fff;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  background-color: #2e2e77;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  ul {
    list-style: none;
    padding-left: 0;
    text-align: left;
    margin-top: 30px;
    color: white; /* Ensure text color is white */

    li {
      margin-bottom: 0.5rem;
      position: relative;
      padding-left: 1.5em;
      font-size: 0.9rem;
    }

    li::before {
      content: '•';
      color: #ec2b91; /* Set bullet color to pink */
      position: absolute;
      left: 0;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
`;

export const ServiceTitle = styled.h3`
  margin: 1rem;
  font-size: 1.5rem;
`;

export const ServiceImage = styled.img`
  width: 80%;
  border-radius: 20px;
  padding: 10px;
`;

export const ServiceDescription = styled.ul`
  list-style: none;
  padding: 20px 0;
  text-align: left;
  margin-top: 10px;

  li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.5em;
    font-size: 1rem;
  }

  li::before {
    content: '•';
    color: #2e2e77;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
`;
