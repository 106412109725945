import React, { useRef, useEffect, useState } from 'react';
import GlobalStyles from '../../styles/global';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import ContactForm from '../../components/ContactForm';
import AboutUs from '../../components/AboutUs';
import Services from '../../components/Services';
import Team from '../../components/Team';
import Footer from '../../components/Footer';

const Home: React.FC = () => {
  const aboutUsRef = useRef<HTMLElement>(null);
  const servicesRef = useRef<HTMLElement>(null);
  const contactRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isContactFormVisible, setContactFormVisible] = useState(false);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    window.scrollTo({
      top: ref.current?.offsetTop! - headerHeight,
      behavior: 'smooth',
    });
  };

  const handleContactClick = () => {
    setContactFormVisible(true);
    scrollToSection(contactRef);
  };

  const handleCloseContactForm = () => {
    setContactFormVisible(false);
  };

  return (
    <>
      <GlobalStyles />
      <Header
        ref={headerRef}
        onWorkClick={() => scrollToSection(servicesRef)}
        onAboutClick={() => scrollToSection(aboutUsRef)}
        onContactClick={handleContactClick}
      />
      <HeroSection />
      <section ref={servicesRef}>
        <Services onContactClick={handleContactClick}/>
      </section>
      <section ref={aboutUsRef}>
        <AboutUs />
      </section>
      <Team />
      <section ref={contactRef} style={{ paddingTop: `2%`, paddingBottom: '2%' }}>
        {isContactFormVisible && <ContactForm onClose={handleCloseContactForm} />}
      </section>
      <Footer
        onWorkClick={() => scrollToSection(servicesRef)}
        onAboutClick={() => scrollToSection(aboutUsRef)}
        onContactClick={handleContactClick}
      />
    </>
  );
};

export default Home;
