import { useState, useEffect } from 'react';

const useLocation = () => {
  const [country, setCountry] = useState('');
  const [showLeadGen, setShowLeadGen] = useState(false);

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country_name);
        const excludedCountries = ['United States', 'United Kingdom', 'Germany', 'France', 'Italy', 'Ukraine', 'Poland']; // Adjust the list as needed
        setShowLeadGen(!excludedCountries.includes(data.country_name));
      });
  }, []);

  return { country, showLeadGen };
};

export default useLocation;
