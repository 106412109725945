import styled from 'styled-components';

export const TeamContainer = styled.section`
  padding: 50px 0;
  background-color: #f9f9f9;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const TeamGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MemberName = styled.h3`
  margin-top: 1rem;
  font-size: 1.2rem;
  transition: color 0.3s ease;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const MemberRole = styled.p`
  font-size: 1rem;
  color: #777;
  transition: color 0.3s ease;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const TeamMember = styled.div`
  width: 200px;
  text-align: center;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #2e2e77;
  }

  &:hover ${MemberName}, &:hover ${MemberRole} {
    color: #2e2e77; /* Change text color to blue on hover */
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const MemberImage = styled.img`
  width: 90%;
  border-radius: 10%;
  filter: grayscale(45%);

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

