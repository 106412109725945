import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ToastContainer = styled.div<{ type: 'success' | 'error' }>`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: ${({ type }) => (type === 'success' ? '#4CAF50' : '#F44336')};
  color: white;
  border-radius: 5px;
  animation: ${fadeIn} 0.5s ease-in-out;
  z-index: 1000;
`;