import React from 'react';
import { FooterContainer, FooterContent, SocialLinks, SocialLink, CopyRight, FooterLinks, Address, FooterMain } from './styles';
import NavLink from '../NavLink';

interface FooterProps {
  onWorkClick: () => void;
  onAboutClick: () => void;
  onContactClick: () => void;
}

const Footer: React.FC<FooterProps> = ({ onWorkClick, onAboutClick, onContactClick }) => (
  <FooterContainer>
    <FooterContent>
      <Address>
        One Park Drive 11th Drive, <br />
        corner 9th Ave<br />
        Taguig, 1634 Metro Manila<br />
        Philippines
      </Address>
      <FooterMain>
        <FooterLinks>
          <NavLink onClick={onWorkClick} customStyle="background-color: transparent; color: white; &:hover { color: #ec2b91; background-color: transparent; }">Work</NavLink>
          <NavLink onClick={onAboutClick} customStyle="background-color: transparent; color: white; &:hover { color: #ec2b91; background-color: transparent; }">About Us</NavLink>
          <NavLink onClick={onContactClick} customStyle="background-color: transparent; color: white; &:hover { color: #ec2b91; background-color: transparent; }">Contact</NavLink>
        </FooterLinks>
        <SocialLinks>
          <SocialLink href="https://www.linkedin.com/company/ledgens/" target="_blank" rel="noopener noreferrer">LEDGENS LinkedIn</SocialLink>
        </SocialLinks>
        <CopyRight>Designed by LEDGENS Team | © 2024 LEDGENS. All rights reserved.</CopyRight>
      </FooterMain>
      <Address/>
    </FooterContent>
  </FooterContainer>
);

export default Footer;
