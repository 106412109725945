import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const FooterContent = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  text-align: left;

  @media (max-width: 1240px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Address = styled.div`
  flex: 1;
  font-size: 0.9rem;
  color: #bbb;
  text-align: left;

  @media (max-width: 1240px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const FooterMain = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterLinks = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 780px) {
    gap: 10px;
  }
`;

export const SocialLinks = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const SocialLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.0rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ec2b91;
  }
`;

export const CopyRight = styled.p`
  margin-top: 10px;
  font-size: 0.9rem;
  color: #777;
`;
