import styled from 'styled-components';

export const AboutContainer = styled.section`
  display: flex;
  justify-content: center;
  padding: 50px;
  background-color: #333;
  color: white;

  @media (max-width: 1024px) {
    padding: 30px 10px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  gap: 10%;

  @media (max-width: 1024px) {
    max-width: 100%;
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  max-width: 1200px;

  @media (max-width: 1024px) {
    max-width: 80%;
  }
`;

export const Imagem = styled.img`
  width: 400px;
  height: auto;
  border-radius: 10px;

  @media (max-width: 1024px) {
    width: 35%;
  }

  @media (max-width: 1024px) {
    display: none !important;
  }
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 2rem;
  }
`;

export const Paragraph = styled.p`
  line-height: 1.8;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: justify;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }

`;
