import React from 'react';
import { StyledButton } from './styles';

interface ButtonProps {
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
  }

  const Button: React.FC<ButtonProps> = ({ children, ...props }) => (
    <StyledButton {...props}>{children}</StyledButton>
  );
  
export default Button;