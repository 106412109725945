import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: #2e2e77;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ec2b91;
  }
  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
`;

