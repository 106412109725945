import React from 'react';
import { StyledNavLink } from './styles';

interface NavLinkProps {
    onClick: () => void;
    children: React.ReactNode;
    customStyle?: string;
  }
  
  const NavLink: React.FC<NavLinkProps> = ({ onClick, children, customStyle }) => {
    return (
      <StyledNavLink onClick={onClick} $customStyle={customStyle}>
        {children}
      </StyledNavLink>
    );
  };
  
  export default NavLink;