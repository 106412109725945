import styled, { css } from 'styled-components';

export const StyledNavLink = styled.a<{ $customStyle?: string }>`
  padding: 10px 20px;
  background-color: #ffffff;
  color: #2e2e77;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #2e2e77;
    color: #ffffff;
  }

  &:active {
    scroll-behavior: smooth;
  }

  ${(props) => props.$customStyle && css`${props.$customStyle}`}
`;