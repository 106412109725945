import React from 'react';
import { MemberImage, MemberName, MemberRole, TeamContainer, TeamGrid, TeamMember, Title } from './styles';
import suzetteImage from '../../assets/Suzette.png';
import kareenImage from '../../assets/Kareen.png';
import virginiaImage from '../../assets/virginia.png';
import cindyImage from '../../assets/Cindy.webp';
import alexImage from '../../assets/Alex.png';
import isaImage from '../../assets/Isa.png';
import chamiImage from '../../assets/Chami.jpg';
import kimImage from '../../assets/Kim.jpg';
import thaynaImage from '../../assets/Thayna.png';
//import ivyImage from '../../assets/Ivy.png';

const Team: React.FC = () => (
  <TeamContainer>
    <Title>Our Team</Title>
    <TeamGrid>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/suzettearchela', '_blank')}>
        <MemberImage src={suzetteImage} alt="Suzette Archela" />
        <MemberName>Suzette Archela</MemberName>
        <MemberRole>Co Founder</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/workwithkd', '_blank')}>
        <MemberImage src={kareenImage} alt="Kareen Degracia" />
        <MemberName>Kareen Degracia</MemberName>
        <MemberRole>Co Founder</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/alex-archela-234ba1b7', '_blank')}>
        <MemberImage src={alexImage} alt="Alex Archela" />
        <MemberName>Alex Archela</MemberName>
        <MemberRole>CTO</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/maryisabellevargas', '_blank')}>
        <MemberImage src={isaImage} alt="Mary Isabelle Vargas" />
        <MemberName>Mary Isabelle Vargas</MemberName>
        <MemberRole>Business Development</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/cindy-aplasca-762b1930a', '_blank')}>
        <MemberImage src={cindyImage} alt="Cindy Aplasca" />
        <MemberName>Cindy Aplasca</MemberName>
        <MemberRole>Data Analyst</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/charmaine-marquez-384761283', '_blank')}>
        <MemberImage src={chamiImage} alt="Charmaine Marquez" />
        <MemberName>Charmaine Marquez</MemberName>
        <MemberRole>Data Analyst</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/kjanemanay', '_blank')}>
        <MemberImage src={kimImage} alt="Kimberly Manay" />
        <MemberName>Kimberly Jane Manay</MemberName>
        <MemberRole>Data Analyst</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/virginia-d%C3%A1vila-268081328', '_blank')}>
        <MemberImage src={virginiaImage} alt="Virginia Dávila" />
        <MemberName>Virginia Dávila</MemberName>
        <MemberRole>Data Analyst</MemberRole>
      </TeamMember>
      <TeamMember onClick={() => window.open('https://www.linkedin.com/in/thayn%C3%A1gurisan', '_blank')}>
        <MemberImage src={thaynaImage} alt="Thayná Gurisan" />
        <MemberName>Thayná Gurisan</MemberName>
        <MemberRole>Data Analyst</MemberRole>
      </TeamMember>
      {/*<TeamMember onClick={() => window.open('', '_blank')}>
        <MemberImage src={ivyImage} alt="Ivy Joyce Alcantara" />
        <MemberName>Ivy Joyce Alcantara</MemberName>
        <MemberRole>Data Analyst</MemberRole>
      </TeamMember>*/}
      {/* Add more team members here */}
    </TeamGrid>
  </TeamContainer>
);

export default Team;