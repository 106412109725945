import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  height: 100vh;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  overflow: hidden; /* Ensure content does not overflow the container */
  padding-top: 150px; 

  @media (max-width: 768px) {
    padding: 10px;
    height: 80vh;
  }
`;

export const VideoBackground = styled.video`
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Send the video to the background */
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.45); 
  z-index: 0; /* Ensure it covers the video but is behind the text */
`;

export const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-size: 4.5rem;
  margin-bottom: 1rem;
  z-index: 1; /* Ensure text is above the video */

  @media (max-width: 768px) {
    padding-top: 150px; /* Adjust padding to move text down by 25% */
    font-size: 2.5rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 2rem;
  margin-bottom: 2rem;
  z-index: 1; /* Ensure text is above the video */

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
