import React, { useState } from 'react';
import { FormContainer, FormField, FormLabel, FormInput, FormTextarea, CloseButton } from './styles';
import { sendEmail  } from '../../services/emailService';
import Button from '../Button';
import Toast from '../Toast';

interface ContactFormProps {
  onClose: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    
    try {
      await sendEmail (formData);
      setToastMessage('Form submitted successfully');
      setToastType('success');
    } catch (error) {
      setToastMessage('There was an error submitting the form. Please try again.');
      setToastType('error');
    } finally {
      setIsSubmitting(false);
      setShowToast(true);
    }
  };

  return (
    <>
      {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
      <FormContainer onSubmit={handleSubmit}>
        <CloseButton onClick={onClose}>X</CloseButton>
        <FormField>
          <FormLabel htmlFor="name">Name</FormLabel>
          <FormInput id="name" name="name" type="text" value={formData.name} onChange={handleChange} required />
        </FormField>
        <FormField>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput id="email" name="email" type="email" value={formData.email} onChange={handleChange} required />
        </FormField>
        <FormField>
          <FormLabel htmlFor="subject">Subject</FormLabel>
          <FormInput id="subject" name="subject" type="text" value={formData.subject} onChange={handleChange} required />
        </FormField>
        <FormField>
          <FormLabel htmlFor="message">Message</FormLabel>
          <FormTextarea id="message" name="message" value={formData.message} onChange={handleChange} required />
        </FormField>
        {error && <p>{error}</p>}
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Submit'}
        </Button>
      </FormContainer>
    </>
  );
};

export default ContactForm;
